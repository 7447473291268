/* App.css */

/* General Dark Theme */
body {
  background-color: #121212;
  color: #e0e0e0;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

/* Container */
.app-container {
  padding: 15px;
  max-width: 800px;
  margin: 0 auto;
}

/* Header container to align title and button on the same line */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; /* Adjust spacing as needed */
}

/* Style for the Buy Me A Coffee button */
.bmc-button {
  height: 30px; /* Adjust height as needed */
  cursor: pointer;
}

/* Title */
.title {
  text-align: center;
  font-family: 'Courier New', monospace;
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: lowercase;
  letter-spacing: 5px; /* Slightly spaced out for a premium feel */
  color: #e0e0e0; /* Soft white for a refined look */
  margin: 0;
  padding: 10px 0;
}

.title a {
  text-decoration: none;
  color: inherit;
}

.title a:visited {
  color: inherit; /* Ensures the color remains the same after being visited */
}

.job-count {
  font-size: 0.9rem;
  font-weight: 400;
  color: #b0b0b0; /* Subtle color to blend with the theme */
  margin-top: -5px;
  margin-bottom: 15px;
}

.job-count a {
  text-decoration: none;
  color: inherit;
}

.job-count a:visited {
  color: inherit;
}

.job-count a:hover {
  text-decoration: underline;
}

/* Job Listings */
.job-list {
  list-style: none;
  padding: 0;
}

.job-item {
  background-color: #1e1e1e;
  border-radius: 8px;
  margin-bottom: 10px; /* Reduce spacing between jobs */
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  height: 60px; /* Ensure text fits within the image height */
}

.job-item:hover {
  background-color: #333333;
}

/* Studio Image (Left Side) */
.studio-image {
  width: 96px; /* Slightly reduced size */
  height: 48px;
  object-fit: cover;
  margin-right: 10px; /* Reduce spacing */
  border-radius: 8px;
  background-color: #fff;
  padding: 3px;
}

/* Text Content (Right Side) */
.job-item-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center; /* Align content towards the top */
  height: 100%;
  padding-left: 5px; /* Remove extra padding */
}

/* Job Title & Date Container */
.job-title-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 0px; /* Remove extra spacing */
}

/* Job Title */
.job-title {
  font-size: 0.95rem;
  color: #007BFF;
  text-decoration: none;
  margin: 0; /* Remove default margins */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px; /* Adjust width as needed */
}

.job-title:hover {
  text-decoration: underline;
}

.job-title:visited {
  color: #b0b0b0;
}

/* Job Date */
.job-date {
  font-size: 0.75rem;
  white-space: nowrap;
  margin-left: 10px;
}

/* Studio Name & Location */
.job-info {
  font-size: 0.75rem;
  color: #cccccc;
  margin-top: 0px; /* Remove extra spacing */
}

.job-studio {
  font-weight: 600;
  color: #f0f0f0;
  margin: 0;
  font-size: 0.75rem;
}

.job-location {
  margin: 0;
  font-size: 0.75rem;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.filter-dropdown {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #333333;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  min-width: 146px;
  width: 31%; /* Reduce width of dropdowns */
  margin-right: 10px; /* Add right margin to first dropdown */
}

.search-bar {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #333333;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  flex-grow: 1;
  margin-right: 10px;
  max-width: 196px;
}

.clear-button {
  background-color: #1e1e1e;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #333333;
}


/* Statistics */
.studio-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

/* Ensures studio name and status indicator are on the same line */
.studio-info {
  display: flex;
  align-items: center;
}

.search-bar-stats {
  background-color: #1e1e1e;
  color: #e0e0e0;
  border: 1px solid #333333;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  flex-grow: 1;
  margin-right: 10px;
}

/* Styling for the status indicator */
.status-indicator {
  margin-right: 8px; /* Add space between the indicator and text */
}

.green-circle {
  background-color: green;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.red-square {
  background-color: red;
  width: 10px;
  height: 10px;
}

.yellow-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid yellow;
}

.grey-circle {
  background-color: gray;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.studio-logo {
  width: 100px; /* Adjust as needed */
  height: 50px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #fff;
  padding: 3px;
}

.studio-text {
  font-size: 0.7rem;
  width: 90px;
}

.changelog-container {
  font-size: 0.75rem;
  font-weight: 400;
  color: #b0b0b0;
  margin-bottom: 15px;
  font-family: 'Courier New', monospace;
  border: 1px dotted;
  padding: 5px;
}

.changelog-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.changelog-container li {
  margin-bottom: 4px;
}

.changelog-container h3 {
  margin-bottom: 5px;
  margin-top: 0px;
}

.changelog-container .changelog-studio {
  font-weight: bold;
}


/* Mobile-friendly layout */
@media (max-width: 768px) {
  .app-container {
    padding: 10px;
  }

  .title {
    font-size: 2rem;
    letter-spacing: 3px; /* Reduce spacing for better fit */
  }
  
  .job-count {
	font-size: 0.7rem;  
  }

  .filters {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-dropdown,
  .search-bar, .search-bar-stats {
    margin-bottom: 10px;
	font-size: 0.7rem;
  }
  
  .search-bar, .search-bar-stats {
	margin-right: 0px;
	max-width: 100%;
	font-size: 16px;
  }
  
  .filter-dropdown {
	width: 100%; /* Full width for better usability */
	min-width: 31%;	
  }

  .clear-button {
    width: 100%;
	font-size: 0.7rem;
  }
  
  .job-item-content {
	padding-left: 0px;
	width: 100%;
	margin-bottom: -10px;
	overflow: hidden;
    text-overflow: ellipsis;
  }

  .job-item {
    padding: 10px;
    height: auto;
  }
  
  .job-date {
	font-size: 0.7rem;
	color: #6c6c6c;
	margin-left: 0px;
	margin-top: 5px;
    margin-bottom: 5px;	
  }
  
  .job-info {
	font-size: 0.7rem;
  }
  
  .job-title {
	width: 70%;
  }
  
  .job-title-container {
	display: unset;
  }

  .studio-image {
    max-height: 30px; /* Keep aspect ratio */
	width: 60px;
    object-fit: contain;
    border-radius: 6px;
  }
  
  .filter-dropdown-parent {
	display: flex;
  }
  
  .filter-dropdown:nth-of-type(3) {
	margin-right: 0px;
  }
  
  .studio-grid {
    grid-template-columns: repeat(3, 1fr); /* Minimum 3 per row */
  }

  .studio-logo {
    
  }

  .studio-text {
    font-size: 0.7rem;
    width: 90px;
  }

  .studio-item {
  }
  
  .status-indicator {
    margin-right: 5px; /* Add space between the indicator and text */
  }
  
  .changelog-container {
	font-size: 0.7rem;  
  }

}
